// Disclosure

const navWrapper = document.querySelector('.nav[data-variant="primary"]');

if (navWrapper) {
  const toggleBtn = navWrapper.querySelector('button');
  toggleBtn?.addEventListener('click', () => {
    const isExpanded = toggleBtn.getAttribute('aria-expanded') === 'true';
    if (isExpanded) {
      toggleBtn.setAttribute('aria-expanded', 'false');
    } else {
      toggleBtn.setAttribute('aria-expanded', 'true');
    }
  });
}
